import isEmpty from 'lodash/isEmpty';
import { computed, reactive, ref, watch } from 'vue';

import { balanceService } from '@exchange/libs/balances/service/src';
import { eotcService } from '@exchange/libs/eotc/service/src';
import { feesService } from '@exchange/libs/fees/service/src';
import { ordersService } from '@exchange/libs/order/my-orders/service/src';
import { OrderSnapshotType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import CryptoInfoRest from '@exchange/libs/rest-api/crypto-info-api';
import PersonalRest from '@exchange/libs/rest-api/personal-api';
import WebRest from '@exchange/libs/rest-api/web-api';
import type { VerificationInformation } from '@exchange/libs/rest-api/web-api/customer/verification-information-resource';
import { settingsService } from '@exchange/libs/settings/service/src';
import { subaccountsService } from '@exchange/libs/trading-accounts/service/src';
import { fundsService, type HolderDetails } from '@exchange/libs/transactions/funds/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { sleepService } from '@exchange/libs/utils/sleep/src';
import { WSIncomingEventTypes } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

import accountHistoryChannel, { AccountChannelGetTokenListener } from './AccountHistory.channel';
import handleForbiddenJurisdiction from './handle-forbidden-jurisdiction';
import handleMissingRetailAml from './handle-missing-retail-aml';
import handleTaxAcknowledgement from './handle-tax-acknowledgement';
import handleUKCustomer from './handle-uk-customer';
import type { UserCheckHandlerResponseActionRequired } from './user-check';
import UserModel, { VerificationTypes, AccountStatuses } from './user-model';
import type { AccountUpdateMessage, OrderSnapshot, BalancesSnapshot, WSSTradingUpdate, WSSFundingUpdate } from './wss-account-messages';
import { AccountUpdateActivity } from './wss-account-messages';

type AccountState = {
  accountId: string | undefined;
  accountUIId: string | undefined;
  accountIdHolder: string | undefined;
  accountUser: UserModel | null;
  accountDetails: HolderDetails | undefined;
  accountUserDataRequested: boolean;
  adopter: boolean;
  retailVerificationInfo: VerificationInformation | undefined;
  unsubscribeAccountHistory?: () => Promise<void>;
  unsubscribeTrading?: () => Promise<void>;
};

class AccountService {
  private readonly logPrefix = 'AccountService:';

  private getDefaultState = () => ({
    accountId: undefined,
    accountUIId: undefined,
    accountIdHolder: undefined,
    accountUser: null,
    accountUserDataRequested: false,
    accountDetails: undefined,
    adopter: false,
    currency: undefined,
    language: undefined,
    retailVerificationInfo: undefined,
    unsubscribeAccountHistory: undefined,
    unsubscribeTrading: undefined,
  });

  private readonly state = reactive<AccountState>(this.getDefaultState());

  public accountUserDataRequested = computed(() => this.state.accountUserDataRequested);

  public userName = computed(() => this.state.accountUser?.fullName ?? this.state.accountDetails?.account_holder_name ?? '');

  public accountId = computed(() => this.state.accountId);

  public accountUIId = computed(() => this.state.accountUIId);

  public accountIdHolder = computed(() => this.state.accountIdHolder);

  public isMainAccount = computed(() => Boolean(this.state.accountId && this.isSubaccountMain(this.state.accountId)));

  public isInstantAccount = computed(() => this.state.accountUIId === eotcService.accountIdInstant);

  public userEmail = computed(() => this.state.accountUser?.email || '');

  public accountUser = computed(() => this.state.accountUser);

  public userIsLoaded = computed(() => !isEmpty(this.state.accountUser?.userData));

  public accountStatus = computed(() => this.state.accountUser?.userData.account_status);

  public accountVerificationDate = computed(() => this.state.accountUser?.userData.verification_date);

  public accountStatusApproved = computed(() => this.accountStatus.value === AccountStatuses.APPROVED);

  public retailAccountVerificationInfo = computed(() => this.state.retailVerificationInfo);

  public userIsBlocked = ref(false);

  public userIsVerified = computed(() => {
    const verified = this.state.accountUser?.userData?.verified;

    if (verified === undefined) {
      return false;
    }

    return [VerificationTypes.FULL, VerificationTypes.LIGHT].includes(verified);
  });

  public securityGet = {
    showVerify: ({ isLoaded, isVerified }: { isLoaded: boolean; isVerified: boolean }) => isLoaded && !isVerified,
  };

  public securityShow = {
    verify: computed(() =>
      this.securityGet.showVerify({
        isLoaded: this.userIsLoaded.value,
        isVerified: this.userIsVerified.value,
      }),
    ),
  };

  public showSecurityWarning = computed(() => this.securityShow.verify.value);

  public setAccountUIId = (id?: string) => {
    if (!id) {
      return;
    }

    this.state.accountUIId = id;
  };

  public isSubaccountMain = (accountId: string) => Boolean(accountId === this.state.accountIdHolder);

  private unsubscribeToAccountHistory = () =>
    (this.state.unsubscribeAccountHistory?.() || Promise.resolve()).catch((e) => {
      logger.error(`${this.logPrefix} unsubscribe from ACCOUNT_HISTORY failed`, e);
    });

  public async subscribeToAccountHistory(getAccessToken: AccountChannelGetTokenListener) {
    try {
      await this.unsubscribeToAccountHistory();

      await new Promise<void>((res, rej) => {
        this.state.unsubscribeAccountHistory = accountHistoryChannel.subscribe(
          {
            getAccessToken,
            onMessage: (event) => {
              switch (event.type) {
                case WSIncomingEventTypes.ACTIVE_ORDERS_SNAPSHOT:
                  ordersService.setSnapshot({ event: event as OrderSnapshot, type: OrderSnapshotType.ACTIVE });
                  break;
                case WSIncomingEventTypes.INACTIVE_ORDERS_SNAPSHOT:
                  ordersService.setSnapshot({ event: event as OrderSnapshot, type: OrderSnapshotType.INACTIVE });
                  break;
                case WSIncomingEventTypes.BALANCES_SNAPSHOT:
                  balanceService.setSnapshot(event as BalancesSnapshot);
                  break;
                case WSIncomingEventTypes.ACCOUNT_UPDATE: {
                  const { update } = event as AccountUpdateMessage;

                  switch (update.activity) {
                    case AccountUpdateActivity.TRADING:
                      ordersService.processTradingUpdateAndAdjustBalance(update as WSSTradingUpdate);
                      break;
                    case AccountUpdateActivity.FUNDING:
                      balanceService.processFundingUpdate(update as WSSFundingUpdate);
                      break;
                    default:
                      logger.error(`${this.logPrefix}Unsupported update activity ${update.activity}, ${update}`);
                      break;
                  }
                  break;
                }
                default:
              }
            },
          },
          {
            success: () => res(),
            fail: (error) => rej(error),
          },
        );
      });
    } catch (error) {
      logger.warn(`${this.logPrefix} subscription failed; retrying later`, error);
      await this.unsubscribeToAccountHistory();
      await retryService.waitForNextRetryTick();
      await this.subscribeToAccountHistory(getAccessToken);
    }
  }

  public getAccountId = ({ useSubaccount = true } = {}): Promise<string> => {
    if (useSubaccount) {
      if (this.state.accountId) {
        return Promise.resolve(this.state.accountId);
      }

      return new Promise((resolve) => {
        const watchStopHandle = watch(
          () => this.state.accountId,
          // eslint-disable-next-line consistent-return
          (newValue) => {
            if (newValue) {
              watchStopHandle();
              return resolve(newValue);
            }
          },
        );
      });
    }

    if (this.state.accountIdHolder) {
      return Promise.resolve(this.state.accountIdHolder);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.accountIdHolder,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  private isFetchingUserHolder = ref(false);

  public fetchHolderUser = async () => {
    const accountId = this.state.accountIdHolder;

    if (!accountId) {
      throw new Error('Cannot fetch user without account id');
    }

    if (this.isFetchingUserHolder.value) {
      return;
    }

    this.isFetchingUserHolder.value = true;

    try {
      const [data, accountDetails, identity] = await Promise.all([WebRest.User(accountId).get(), fundsService.accountHolder.get(), this.checkIdentity()]);
      const user = new UserModel(data);

      this.state.accountUser = user;
      this.state.accountDetails = accountDetails;

      /*
       * Note: The order here is important. We should also be mindful of what checks we're doing here as this
       * function is called in several places (page load, deposits and withdraws to name a few)
       */
      const userChecks = await Promise.all([
        handleForbiddenJurisdiction({ email: user.email, residency: identity.residency }),
        handleUKCustomer(identity, accountId, this.fetchHolderUser),
        handleMissingRetailAml(user.isRetail, this.fetchHolderUser),
        handleTaxAcknowledgement(accountId),
      ]);

      const firstActionRequired = userChecks.find((check): check is UserCheckHandlerResponseActionRequired => check.actionRequired === true);

      if (firstActionRequired) {
        firstActionRequired.action();
        this.state.accountUserDataRequested = true;
      }
    } catch (e) {
      const error = (e as { data: unknown | undefined; message: string }).data || (e as { data: unknown | undefined; message: string }).message;

      logger.error(`${this.logPrefix} Failed to fetch user:`, error);
      throw e;
    } finally {
      this.isFetchingUserHolder.value = false;
    }
  };

  public set = async (
    {
      earlyAdopter,
      accountId,
      accountIdHolder,
    }: {
      earlyAdopter: boolean;
      accountId: string;
      accountIdHolder: string;
    },
    getAccessToken: AccountChannelGetTokenListener,
  ) => {
    if (!sleepService.appIsAsleep.value) {
      /** dont open WS connection during "sleep" */ // TODO do not subscribe on eotc page
      // this.subscribeToTrading(getAccessToken);
      this.subscribeToAccountHistory(getAccessToken);
    }

    if (this.state.accountId === accountId) {
      return;
    }

    this.state.accountId = accountId;
    this.state.accountIdHolder = accountIdHolder;
    this.state.adopter = earlyAdopter;

    if (!this.state.accountUIId) {
      this.setAccountUIId(accountId);
    }

    launchdarkly.identify(accountIdHolder);
    settingsService.setupSubscriptions();

    const promises = [
      { fn: () => this.fetchHolderUser(), type: 'fetchHolderUser' },
      { fn: () => this.fetchFees(), type: 'fetchAccountFees' },
      { fn: () => subaccountsService.startListPolling(), type: 'fetchSubaccounts' },
    ];

    const callPromises = async (arr: typeof promises) => {
      const results = await Promise.allSettled(arr.map((p) => p.fn()));

      const rejected = results
        .map((result, i) => {
          if (result.status === 'rejected') {
            logger.warn(`${this.logPrefix} Set user partly failed at: ${arr[i]?.type}; retrying later`);

            return arr[i];
          }

          return undefined;
        })
        .filter((p) => p !== undefined) as typeof promises;

      if (rejected.length) {
        await retryService.waitForNextRetryTick();
        await callPromises(rejected);
      }
    };

    await callPromises(promises);
  };

  public awaitAccountUser = (): Promise<UserModel> => {
    if (this.state.accountUser) {
      return Promise.resolve(this.state.accountUser);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.accountUser,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue);
          }
        },
      );
    });
  };

  public checkIdentity = async () => {
    const [geoInfo, identity] = await Promise.all([
      CryptoInfoRest.GeoInfo.get().catch(() => ({ isoCode: '' })),
      PersonalRest.Identity.get().catch(() => ({ addressOfResidency: { country: '' } })),
    ]);

    return {
      isoCode: geoInfo.isoCode,
      residency: identity?.addressOfResidency?.country ?? '',
    };
  };

  public reset = ({ unsubscribeFromAccountHistory } = { unsubscribeFromAccountHistory: true }) => {
    this.state.accountId = undefined;
    this.state.accountUIId = undefined;
    this.state.accountIdHolder = undefined;
    this.state.adopter = false;
    this.state.accountUser = null;

    balanceService.reset();
    ordersService.reset();
    subaccountsService.reset();

    this.state.accountUserDataRequested = false;

    if (unsubscribeFromAccountHistory) {
      this.unsubscribeToAccountHistory();
      this.state.unsubscribeAccountHistory = undefined;
      this.state.unsubscribeTrading = undefined;
    }
  };

  public fetchFees = async () => {
    const userIsBlocked = await feesService.fetchAccountFees();

    this.userIsBlocked.value = userIsBlocked;
  };
}

const as = new AccountService();

export default as;
